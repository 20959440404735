<template>
  <div>
    <div class="collection--edit__wrap">
      <div class="edit__collection">
        <router-link
          :to="{
            name: 'Collection Edit',
            params: { slug: collectionSlug, id: collectionId }
          }"
        >
          {{ $t("account.account_edit_collection") }}
        </router-link>
      </div>
      <div
        class="remove__collection"
        @click="removeCollectionHandler(collectionId)"
      >
        {{ $t("account.account_remove_collection") }}
      </div>
    </div>
  </div>
</template>
<script>
import { authHeader } from "@/helpers/authHeader";

export default {
  name: "CollectionEdit",
  data() {
    return {};
  },
  props: {
    collectionId: Number,
    collectionSlug: String
  },
  methods: {
    removeCollectionHandler(collectionId) {
      console.log(collectionId);
      this.apiDeleteCollection(collectionId);
      setTimeout(() => {
        this.$emit("collectionList-afterDelete");
      }, 500);
    },

    async apiDeleteCollection(id) {
      try {
        const response = await fetch(
          `${process.env.VUE_APP_BASE_API_URL}/collection/${id}/`,
          {
            method: "DELETE",
            mode: "cors",
            cache: "default",
            headers: authHeader(),
            body: null
          }
        );

        if (!response.ok) {
          throw new Error("API Error");
        }

        const data = await response.json();
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.collection--edit__wrap {
  position: relative;
  top: -20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  .edit__collection,
  .remove__collection {
    text-decoration: underline;
    text-transform: lowercase;
    cursor: pointer;
    user-select: none;
  }
}
</style>
